import { useDispatch, useSelector } from 'react-redux';
import fm from 'format-message';
import useAccountQuery from 'queries/useAccountQuery';
import Link from '@activebrands/core-web/components/Link';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { signOut } from '@activebrands/core-web/state/auth/actions';
import stringReplace from '@grebban/utils/string/replaceSubStrings';
import JohaugIcon from 'components/icons/JohaugIcon';
import LogOutIcon from 'components/icons/LogOutIcon';
import OverlayHeader from 'components/overlays/OverlayHeader';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    width: '100vw',
    padding: '4px',
    height: `calc(var(--vh) - var(--height-header) - 8px)`,
});

const Icon = styled('img', {
    marginRight: '8px',
    height: '16px',
    width: '16px',
});

const InnerWrapper = styled('div', {
    backgroundColor: 'var(--color-bg-secondary)',
    zIndex: 'var(--zindex-header)',
    borderRadius: '10px',
    padding: '0 8px',
    height: '100%',
});

const NavigationWrapper = styled('div', {
    width: '100%',
    zIndex: 'calc(var(--zindex-header)',
    height: '90%',
    marginTop: '20px',
    paddingBottom: '10px',
});

const Navigation = styled('div', {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const Heading = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '10px',
});

const InternalMenu = styled('div', {
    height: '36px',
    width: '100%',
    borderRadius: '40px',
    marginBottom: '10px',
});

const NavigationOverlay = () => {
    const [css] = useStyletron();
    const { internalMenu, welcomeLabel, externalMenu } = useAccountQuery();
    const activeClass = css({
        backgroundColor: 'var(--color-bg-active-navigation)',
        borderRadius: '60px',
    });
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const firstName = { '{firstName}': user.firstname || user.email };
    const replacedName = stringReplace(welcomeLabel, firstName);

    return (
        <Wrapper>
            <InnerWrapper>
                <Heading>
                    <Paragraph $style={{ color: 'var(--color-text-subtle)' }} as="p" fontKeys="Miscellaneous/16_100_-1">
                        {fm('Account')}
                    </Paragraph>
                    <JohaugIcon width="80px" />
                    <OverlayHeader overlayId="account-navigation" />
                </Heading>
                <NavigationWrapper>
                    <Navigation>
                        <ul className={css({ gap: '16px' })}>
                            <div className={css({ marginBottom: '12px', padding: '0 12px' })}>
                                <Paragraph $style={{ marginBottom: '20px', fontSize: '12px' }} as="span">
                                    {replacedName}
                                </Paragraph>
                            </div>
                            {internalMenu &&
                                internalMenu.map((internalMenuItem, index) => (
                                    <InternalMenu key={index}>
                                        <Link
                                            activeClassName={activeClass}
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                padding: '0 12px',
                                            })}
                                            rel="nofollow"
                                            to={internalMenuItem.link.url}
                                        >
                                            <Paragraph
                                                $style={{ whiteSpace: 'noWrap' }}
                                                as="p"
                                                fontKeys="Primary/18_120_-1"
                                            >
                                                {internalMenuItem.label}
                                            </Paragraph>
                                        </Link>
                                    </InternalMenu>
                                ))}
                        </ul>
                        <ul style={{ marginBottom: '10px' }}>
                            {externalMenu &&
                                externalMenu.map((externalMenuItem, index) => {
                                    const icon = externalMenuItem.icon[0]?.icon[0]?.filename;

                                    return (
                                        <li
                                            className={css({
                                                height: '36px',
                                                width: '100%',
                                                borderRadius: '40px',
                                            })}
                                            key={index}
                                        >
                                            <Link
                                                activeClassName={activeClass}
                                                className={css({
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '0 12px',
                                                })}
                                                rel="nofollow"
                                                to={externalMenuItem.link.url}
                                            >
                                                {icon && <Icon src={icon} />}
                                                <Paragraph as="p" fontKeys="Regular-20/100">
                                                    {externalMenuItem.label}
                                                </Paragraph>
                                            </Link>
                                        </li>
                                    );
                                })}
                            <li
                                className={css({
                                    height: '36px',
                                    width: '100%',
                                    borderRadius: '40px',
                                })}
                            >
                                <Link
                                    activeClassName={activeClass}
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        cursor: 'pointer',
                                        padding: '0 12px 12px 12px',
                                    })}
                                    rel="nofollow"
                                    to="/"
                                    onClick={() => dispatch(signOut())}
                                >
                                    <LogOutIcon $style={{ marginRight: '8px' }} />
                                    <Paragraph as="p" fontKeys="Regular-20/100">
                                        {fm('Log out')}
                                    </Paragraph>
                                </Link>
                            </li>
                        </ul>
                    </Navigation>
                </NavigationWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};

export default NavigationOverlay;
